<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('Launcher1CVersionCreate')"
    @edit="(id) => onEdit('Launcher1CVersionEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'Launcher1CVersions',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'Launcher1CVersions',
      tableCaption: 'Версии клиента 1С',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Версии клиента 1С',
        },
      ],
      tableHeaders: [
        { text: 'Версия', alias: 'version', order: 'version' },
        { text: 'Дата', alias: 'date', order: 'date' },
        { text: 'Доп. клиент', alias: 'isAdditional', order: 'isAdditional' },
        { text: 'Ссылка', alias: 'link', order: 'link' },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },
};
</script>
